import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Router } from '@angular/router';
import { getMetaReducers } from './state/app.reducer';
import {
  Configuration,
  ConfigurationParameters,
  TravelPlannerApiModule
} from '@travel-planner/library-travel-planner-api';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { registerLocaleData } from '@angular/common';
import localeDECH from '@angular/common/locales/de-CH';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { allIcons } from './modules/shared/icons/all';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    pan: {
      enable: () => window.screen.width < 600
    },
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

registerLocaleData(localeDECH);

export const apiConfigFactory = (): Configuration => {
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl
  };
  return new Configuration(params);
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    MatMomentDateModule,

    MatDialogModule,

    TravelPlannerApiModule.forRoot(apiConfigFactory),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.environment === 'production' }),
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      deps: [Router],
      useFactory: getMetaReducers
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: LOCALE_ID, useValue: 'de-CH' },
    provideSvgIcons(allIcons),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
