import { ActionReducer, MetaReducer } from '@ngrx/store';
import { AppActions } from './app.actions';
import { Router } from '@angular/router';

export function navigateOnSuccess(router: Router): MetaReducer {
  return (reducer: ActionReducer<any>): ActionReducer<any> => {
    return function (state, action: any) {

      if (action.type === AppActions.navigateOnSuccess.type) {
        router.navigate(action.navigationDescription?.url, { queryParams: action.navigationDescription?.queryParams });
      }

      return reducer(state, action);
    };
  };
}

export function getMetaReducers(router: Router): MetaReducer<any>[] {
  return [navigateOnSuccess(router)];
}
