import { canoeingIcon } from './canoeing';
import { hotelIcon } from './hotel';
import { landCruiserIcon } from './land-cruiser';
import { locationIcon } from './location';
import { lodgeIcon } from './lodge';
import { mealIcon } from './meal';
import { planeIcon } from './plane';
import { priceIcon } from './price';
import { tentIcon } from './tent';
import { textIcon } from './text';
export const allIcons = [canoeingIcon, hotelIcon, landCruiserIcon, locationIcon, lodgeIcon, mealIcon, planeIcon, priceIcon, tentIcon, textIcon];
