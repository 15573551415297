import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToPlatform = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: 'platform',
    loadChildren: () => import('./modules/platform/platform.module').then(m => m.PlatformModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedInToPlatform }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'platform/inquiries'
  },
  {
    path: '**',
    redirectTo: 'platform/not-found',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
