import { Environment } from './environment.interface';

export const environment: Environment = {
  environment: 'dev',
  apiUrl: 'https://api.planner.tanzania-travelers.ch/api/v1',
  baseUrl: 'https://planner.tanzania-travelrs.ch/',
  imagesBaseUrl: 'https://planner.tanzania-travelers.ch/images/accommodations/',
  firebaseConfig: {
    apiKey: 'AIzaSyCQYbHDTKEEcnRBMw5VnbqrMZ4w4Q7zMC8',
    authDomain: 'travel-planner-dev-d972d.firebaseapp.com',
    projectId: 'travel-planner-dev-d972d',
    storageBucket: 'travel-planner-dev-d972d.appspot.com',
    messagingSenderId: '915370163545',
    appId: '1:915370163545:web:bbc4160c51c3111f1ae048'
  }
};
