import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { Auth } from '@angular/fire/auth';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private auth: Auth) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const currentUser = this.auth.currentUser;
    if (currentUser != null) {
      return from(currentUser.getIdToken()).pipe(
        switchMap(token => {
          const authenticatedRequest = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
          return next.handle(authenticatedRequest);
        })
      );
    }

    return next.handle(request);
  }
}
